import request from "@/utils/request";

//新增标签
export function addTag (data) {
    return request({
        url: "/callcenter/callCenterFlag/addCallCenterFlag",
        method: "post",
        data
    })
}
//删除标签
export function deleteTag (data) {
    return request({
        url: "/callcenter/callCenterFlag/deleteCallCenterFlag",
        method: "DELETE",
        params: data
    })
}
//编辑标签
export function editTag (data) {
    return request({
        url: "/callcenter/callCenterFlag/updateCallCenterFlag",
        method: "post",
        data,
    })
}

//分页标签列表
export function getTagsList (data) {
    return request({
        url: "/callcenter/callCenterFlag/selectCallCenterFlagPage",
        method: "post",
        data
    })
}

//不分页标签列表(启用)
export function getTags () {
    return request({
        url: "/callcenter/callCenterFlag/selectCallCenterFlagList",
        method: "post",
    })
}

//不分页标签列表(启用+禁用)
export function getTagsAll () {
    return request({
        url: "/callcenter/callCenterFlag/selectCallCenterFlagAll",
        method: "post",
    })
}


//标签的禁用和启用
export function IsDisableAndEnabled (data) {
    return request({
        url: "/callcenter/callCenterFlag/IsDisableAndEnabled",
        method: 'PUT',
        params: data,
    })
}

//客户打标
export function addCustomerFlag (data) {
    return request({
        url: "/callcenter/callCenterCustomerFlag/addCustomerFlag",
        method: 'POST',
        data,
    })
}

//客户标签列表
export function selectCallCenterCustomerFlag (data) {
    return request({
        url: "/callcenter/callCenterCustomerFlag/selectCallCenterCustomerFlag",
        method: 'get',
        params: data,
    })
}








