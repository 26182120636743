<template>
  <div id="tags">
    <div className="cc-list-content">
      <div className="cc-search-wrap">
        <a-form ref="search_form" :model="searchForm" layout="inline">
          <a-form-item label="Status">
            <a-select v-model:value="searchForm.status" placeholder="Please Select">
              <a-select-option :key="0">Enable</a-select-option>
              <a-select-option :key="1">Disable</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item style="margin-left: 5px">
            <a-button style="margin-right: 10px" type="primary" @click="getAllData">Search</a-button>
            <a-button @click="reset">Reset</a-button>
          </a-form-item>
        </a-form>
      </div>
      <div class="addChildAccount">
        <a-button type="primary" @click="addTags(0)">
          <plus-outlined />Add Tag
        </a-button>
      </div>
      <a-table :loading="loading" :dataSource="dataList" :columns="columns" :pagination="pagination" @change="changePages">
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'action'">
            <a href="javascript:;" @click="addTags(1, record)">Edit</a>
            <a-divider type="vertical" />
            <a-popconfirm v-if="record.status==0" title="Are you sure to disable the tag?" ok-text="OK" cancel-text="Cancel" @confirm="toggleTags(1,record)">
              <a>Disable</a>
            </a-popconfirm>
            <a v-else @click="toggleTags(0,record)">Enable</a>
            <a-divider type="vertical" />
            <a-popconfirm title="After delete, the marked tag will be deleted and can't recovered. Continue?" ok-text="OK" cancel-text="Cancel" @confirm="deletTags(record.id)">
              <a href="javascript:;">Delete</a>
            </a-popconfirm>
          </template>
          <template v-if="column.key === 'color'">
            <div class="colorDiv" :style="{'background-color':`${record.color}`}"></div>
          </template>
          <template v-if="column.key === 'status'">
            <a-tag v-if="record.status==0" color="success">Enable</a-tag>
            <a-tag v-else color="warning">Disable</a-tag>
          </template>
        </template>
      </a-table>
    </div>
    <a-modal :visible="visible" :title="isEdit?'Edit Tag':'Add Tag'" @cancel="closeModal" forceRender>
      <a-form :model="submitForm" ref="submitFormRef" :rules="rules" :labelCol="{ span: 5 }" :wrapperCol="{ span: 17 }">
        <a-form-item label="Tag Name" name="name">
          <a-input v-model:value="submitForm.name" placeholder="Tag Name" />
        </a-form-item>
        <a-form-item label="Sort" name="flagSort">
          <a-input v-model:value.number="submitForm.flagSort" placeholder="Sort" />
        </a-form-item>
        <a-form-item label="Color" name="color">
          <a-input style="width:42px" type="color" v-model:value="submitForm.color" placeholder="Color" />
        </a-form-item>
      </a-form>
      <template #footer>
        <div>
          <a-button @click="closeModal">Cancel</a-button>
          <a-button type="primary" @click="submit">Confirm</a-button>
        </div>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { PlusOutlined } from '@ant-design/icons-vue';
import * as API_Tag from "@/api/tags";
import { message } from 'ant-design-vue'
import { defineComponent, reactive, toRefs, ref } from 'vue'
const columns = [
  {
    title: 'Tag Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Sort',
    dataIndex: 'flagSort',
    key: 'flagSort',
  },
  {
    title: 'Color',
    key: 'color',
  },
  {
    title: 'Status',
    key: 'status',
  },
  {
    title: 'Operate',
    key: 'action',
    align: 'center',
  },
];
export default defineComponent({
  components: { PlusOutlined },
  setup () {
    const data = reactive({
      loading: false,
      isEdit: false,
      visible: false,
      dataList: [],
      searchForm: {
        status: undefined,
        pageNum: 1, //页码
        pageSize: 10, //页大小
      },
      submitForm: {
        name: '',
        color: '#ff0000',
        flagSort: 0,
        id: undefined
      },
      pagination: {
        showTotal: (total) => `total ${total}`,
        total: 0,
        pageSize: 10,
        hideOnSinglePage: true,
        current: 1,
        showSizeChanger: true,
      },
      columns,
      rules: {
        name: [
          { required: true, message: 'Please fill in tag name', trigger: 'blur' },
        ],
        flagSort: [
          { required: true, message: 'Please fill in flagSort', trigger: 'blur' },
        ],
        color: [
          { required: true, message: 'Please select color', trigger: 'blur' },
        ],
      }
    })
    const submitFormRef = ref()
    function getAllData () {
      data.searchForm.pageNum = 1
      getTags()
    }
    function toggleTags (type, record) {
      API_Tag.IsDisableAndEnabled({ id: record.id, status: type }).then(res => {
        if (type == 0) {
          message.success('Enabled successfully')
        } else {
          message.success('Disabled successfully')
        }
        getTags()
      }).catch(err => {
        message.error('Disable failed')
        getTags()
      })
    }
    function changePages (pages) {
      data.searchForm.pageNum = pages.current
      data.searchForm.pageSize = pages.pageSize
      getTags()
    }
    function getTags () {
      data.loading = true
      API_Tag.getTagsList(data.searchForm).then((res) => {
        data.dataList = res.data.records
        data.pagination.total = res.data.total
        data.pagination.pageSize = res.data.size
        data.pagination.current = res.data.current
        data.loading = false
      }).catch(err => {
        data.loading = false
      })
    }
    // 关闭编辑/新增弹框
    function closeModal () {
      data.visible = false
      data.isEdit = false
      const color = data.submitForm.color
      submitFormRef.value && submitFormRef.value.resetFields()
      data.submitForm = {
        name: '',
        color,
        flagSort: undefined,
        id: undefined
      }
    }
    function addTags (openType, record) {
      if (openType) {
        data.isEdit = true
        data.submitForm.id = record.id
        data.submitForm.name = record.name
        data.submitForm.color = record.color
        data.submitForm.flagSort = record.flagSort
      } else {
        data.isEdit = false
      }
      data.visible = true
    }
    //搜索表单重置
    function reset () {
      data.searchForm = {
        pageNum: 1,
        pageSize: 10,
        status: undefined,
      }
      getTags()
    }
    //删除标签
    function deletTags (id) {
      API_Tag.deleteTag({ id }).then((res) => {
        message.success('Delete successfully!')
        getTags()
      })
    }
    // 添加标签 
    function submit () {
      submitFormRef.value.validate().then((res) => {
        if (!data.isEdit) {
          API_Tag.addTag(data.submitForm).then((res) => {
            message.success('Add successfully!')
            closeModal()
            getTags()
          })
        } else {
          API_Tag.editTag(data.submitForm).then((res) => {
            message.success('Edit successfully!')
            closeModal()
            getTags()
          })
        }
      })
    }
    //获取标签
    getTags()
    return {
      ...toRefs(data),
      submitFormRef,
      toggleTags,
      changePages,
      getTags,
      closeModal,
      addTags,
      reset,
      deletTags,
      submit,
      getAllData
    }
  },
})
</script>

<style lang="less" scoped>
@import '../../assets/cc-list-tap-container.less';
@import '../../assets/cc-search-wrap.less';
#tags {
  .addChildAccount {
    margin-bottom: 15px;
  }
}
.footer {
  width: 100%;
  display: flex;
  justify-content: center;
}
.colorDiv {
  width: 24px;
  height: 24px;
}
</style>
